import axios from 'axios'
import {
  Message,
  Loading
} from 'element-ui' //项目中我用了element-ui组件库，Message是一个消息弹框，Loading是加载图,按需导入

import router from '../router/index.js'  //注意路径与文件名
import _ from 'lodash'
const service = axios.create({
  timeout: 50000 // request timeout
})


 
let needLoadingRequestCount = 0
let loading
 
function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '正在加载',
    background: 'rgba(0, 0, 0, 6)'
  })
}
 
function endLoading() {
  loading.close()
}
 
const tryCloseLoading = () => {
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}
 
export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}
 
export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    _.debounce(tryCloseLoading, 100)()  //延迟100ms
  }
}


export default service
